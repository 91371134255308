footer {
    height: 30vh;
    max-width: 100vw;
    overflow: hidden;
}
#FooterId {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f2f2;
    color: #171717;
}

.footerTitle {
    font-size: 28px;
    margin-bottom: 20px;
    position: relative;
    padding: 30px;
    width: 70%;
}

.footerTitle::after {
    content: "";
    position: absolute;
    bottom: -180px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #1f1e1e;
    
}

.contactItem {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin: 15px 0;
    justify-content: space-between;
}

.contactIcon {
    display: flex;
    align-items: center;
    font-size: 25px;
}

.footerDark {
    background-color: rgb(27, 27, 27) !important;
    color:  rgb(156, 155, 155);
    .footerTitle {
        color:  rgb(156, 155, 155);
    }
    .contactIcon{
        color:  rgb(156, 155, 155);
    }
    .contactItem {
        color:  rgb(156, 155, 155);
    }
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 768px) {
    #FooterId {
        padding: 20px;
        height: 400px;
    }

    .footerTitle {
        font-size: 24px;
        width: 90%;
        text-align: center;
    }

    .df {
        width: 100% !important;
        flex-direction: column;
        align-items: center;
    }

    .contactItem {
        font-size: 18px;
        width: 90%;
        justify-content: center;
        text-align: center;
    }

    .contactIcon {
        margin-right: 10px;
    }
}