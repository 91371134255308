* {
    margin: 0;
    padding: 0;
}

.customDropdown {
    position: relative;
    font-family: Arial, sans-serif;
    flex: 1;
    border-bottom: none; /* Убираем нижнюю границу, чтобы избежать двойной границы */
}
.currentOption{
    padding: 5px 10px;
    width: 180px;
    background-color: #fff;
    cursor: pointer;
    border: 1px solid rgb(217, 216, 216);
    border-radius: 0 15px 15px 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow:  6px 3px 10px rgba(0.3, 0, 0, 0.1); /* Это добавляет тень */
}

.optionsList {
    padding: 5px;
    border-radius: 15px;
    position: relative;
    width:450px;
    border: 1px solid rgb(217, 216, 216);
    background-color: rgb(255, 255, 255);
    z-index: 1000;
    margin-top: 20px;
     left: -60%;
}

.unitOptions {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    border-bottom: 1px solid rgb(95, 94, 94);
}

.option {
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.option:hover {
    background-color: #f5f5f5;
}

.code {
    margin-left: 10px;
    color: #888;
}

.click {
    cursor: pointer;
}


.darkcustomDropdown  {
    .currentOption{
        background-color:  rgb(27, 27, 27);
        border: 1px solid rgb(31, 31, 31);
        box-shadow:  6px 3px 10px rgba(173, 170, 170, 0.1); /* Это добавляет тень */
        position: relative;
        font-family: Arial, sans-serif;
        width: 180px;
        border-bottom: none;
    }
    .optionsList {
        background-color: #1f1e1e;
    }
}


@media (max-width: 768px) {
    .customDropdown {
        width: 100% !important;
        max-height: fit-content;
        .currentOption{
            border-radius: 15px 15px 15px 15px !important;
            width: 100%;
            box-shadow: none !important;
        }
        .optionsListCrypto {
            width: 100% !important;
            left: 0 !important;
            font-size: 15px;
        }
    }

}