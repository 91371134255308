* {
    margin: 0;
    padding: 0;
    font-size: 30px;
    font-weight: 1px;
}

.customDropdown {
    position: relative;
    font-family: Arial, sans-serif;
    width: 180px;
    border-bottom: none;
}

.currentOption{
    padding: 5px 10px;
    width: 180px;
    background-color: #fff;
    cursor: pointer;
    border: 1px solid rgb(217, 216, 216);
    border-radius: 0 15px 15px 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow:  6px 3px 10px rgba(0.3, 0, 0, 0.1); /* Это добавляет тень */
}



.optionsListCrypto {
    padding: 5px;
    border-radius: 15px;
    position: relative;
    width: 132%;
    border: 1px solid rgb(217, 216, 216);
    background-color:  rgb(255, 255, 255);
    z-index: 1000;
    margin-top: 20px;
     left: -16%;
}


.unitOptions {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    border-bottom: 1px solid rgb(217, 216, 216);
}

.option {
    text-wrap: nowrap;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.option:hover {
    background-color: #f5f5f5;
}

.code {
    margin-left: 10px;
    color: #888;
}


.darkcustomDropdown {
    .currentOption{
        background-color:  rgb(27, 27, 27);
        border: 1px solid rgb(31, 31, 31);
        box-shadow:  6px 3px 10px rgba(173, 170, 170, 0.1); /* Это добавляет тень */
        position: relative;
        font-family: Arial, sans-serif;
        width: 180px;
        border-bottom: none;
    }
    .optionsListCrypto {
        background-color: #1f1e1e;
    }
}


/* Стили для мобильных устройств */
@media (max-width: 768px) {
    .customDropdown {
        width: 100% !important;
        border-radius: 15px 15px 15px 15px !important;
        .currentOption{
            width: 100%;
            max-height: 50px;
            box-shadow: none !important;
        }
        
        .optionsList {
            width: 100% !important;
            left: 0% !important;
            .option{
                font-size: 20px;
            }
            .code {
                font-size: 25px;
            }
        }
    }

}