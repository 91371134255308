header {
    background-color: whitesmoke;
    border-bottom: 1px solid rgb(156, 155, 155);
    height: 70px;
    max-width: 100vw;
    display: flex;
    justify-content: space-between;

    .tab {
        height: 100%;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        .dark {
            color:  rgb(156, 155, 155);
        }
        p {
            font-size: 30px;
            font-weight: 500;
            color: rgb(80, 79, 79);
        }
    }
    .tabLeft{
        height: 100%;
        background-color: transparent;
        display: flex;
        justify-content: end;
        align-items: center;
        flex: 1;
        .box {
            padding-right: 20px;
            display: flex;
            flex-direction: row;
        }
    }
}

.dark{
    background-color: #0e0e0e;
    color:  rgb(156, 155, 155);
}

/* Стили для мобильных устройств */
@media (max-width: 768px) {
    header {
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: 10px 0;
    }

    .tab, .tabLeft {
        flex: unset;
        width: 100%;
        justify-content: center;
        margin-bottom: 10px;
    }

    .tabLeft .box {
        justify-content: center;
    }

    p {
        font-size: 24px;
    }
}