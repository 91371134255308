
.contentContainer {
    max-width: 100vw;
    min-height: calc(100vh - 70px);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .adsContent {
        width: 100%;
        height: fit-content;
        margin: 30px 0 80px 0;
        display: flex;
        flex-direction: column;
        .BannerColumn{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: space-between;
            .gifs {
                margin: 5px;
                // min-width: 0;
                // flex: 1;
            }
            // padding: 5px 0;
        }
    }
    
    .boxConverter {
        height: 100px;
        border: 1px solid rgb(217, 216, 216);
        border-radius: 30px;
        padding: 15px;
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 80px;
        
        p {
            font-size: 45px;
            font-weight: 200;
            line-height: 130%;
        }

        .values {
            margin: 5px;
            display: flex;
            flex-direction: row;
            
            .inputCount,
            .inputCountResult {
                width: 400px !important;
                transition: color 0.9s;
                outline: none;          /* Убрать фокус при клике */
                background: transparent; /* Сделать фон прозрачным */
                appearance: none;       /* Убрать стандартное отображение для некоторых браузеров */
                -webkit-appearance: none; /* То же самое для Webkit браузеров */
                -moz-appearance: none;    /* То же самое для Firefox */
            }
            
            .inputCount, 
            .inputCountResult {
                border-radius: 15px 0 0 15px;
                height: 100%;
                padding: 0 15px;
                border: 1px solid rgb(217, 216, 216);
                box-shadow:  6px 3px 10px rgba(0.3, 0, 0, 0.1);
                margin-right: 10px;
                text-align: center;
            }
            
            /* Если у вас также есть стрелки для выбора чисел в полях input[type="number"], уберите их: */
            .inputCount::-webkit-inner-spin-button,
            .inputCount::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            .inputCount.load, 
            .inputCountResult.load {
                color: gray;
            }
        }
        
    }
}

/* Добавьте этот стиль для скрытого инпута */
.hiddenInput,
.hiddenInputResult {
    position: absolute;
    top: -9999px;
    left: -9999px;
    white-space: nowrap;
}

.dark{
    background-color: #0e0e0e;
}
.valuesDark {
    .inputCount, 
    .inputCountResult {
        color: antiquewhite;
        background-color:  rgb(27, 27, 27) !important;
        border: 1px solid rgb(31, 31, 31) !important;
        box-shadow:  1px 3px 10px rgba(173, 170, 170, 0.1) !important ; /* Это добавляет тень */
    }
}
.boxConverterDark {
    border: 1px solid rgb(33, 33, 33) !important;
    box-shadow:  1px 3px 10px rgba(173, 170, 170, 0.01) !important ; /* Это добавляет тень */
}



/* Стили для мобильных устройств */
@media (max-width: 768px) {
    .contentContainer {
        // min-height: calc(100vh - 70px);
        .adsContent {
            margin: 0px !important;
            flex-direction: column !important;
            .BannerColumn{
                display: flex;
                flex-direction: column !important;
                .gifs{
                    margin: 5px;
                }
            }
        }
    }

    .boxConverter {
        height: 550px !important;
        padding: 0 !important;
        border: none !important;
        border-radius: 0 !important;
        flex-direction: column !important;
        justify-content: center;
        
        p {
            font-size: 20px;
        }

        .values {
            padding: 15px;
            width: 100% !important;
            margin: 0 !important;
            display: flex;
            flex-direction: column !important;
            .inputCount, 
            .inputCountResult {
                border-radius: 15px 15px 15px 15px !important;
                padding: 0 15px;
                // margin-right: 0 !important;
                width: 100% !important;
                margin-bottom: 10px;
                box-shadow: none !important;
            }
        }
    }
}